<div class="main-container">
    <div class="login-container">
        <div class="back-cover-container">
        </div>
        <div class="login-body">
            <img src="https://karrierevejviser.dk/images/dynamic/company/logo/127551/" class="logo" alt="">
            <label class="text-big">Sign in to your account</label>
            
            <button class="submit" id="send-link-btn" (click)="loginWithEmail()">Sign in</button>
        
            <!-- Message area -->
            <p id="response-message" class="message"></p>
        </div>
        <div class="login-footer">
            <label class="footer-text">
                V1.18 Copyrights © 2024 ReFlow ApS - All rights reserved
            </label>
        </div>
        
    </div>
    <div class="cover-container">

    </div>
</div>


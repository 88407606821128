import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/yardscore-services/users.service';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {

  public currentUser: any;
  public token: any;
  public userObtained:boolean=false;

  constructor(private userService: UserService,private auth: AuthService, private router: Router) { }

  public navigateFromLogin(): void {
    localStorage.setItem("access_token",this.currentUser.token);
    localStorage.setItem("companyId",this.currentUser.companyId);
    this.router.navigate([this.currentUser.redirectTo]);
  }

  isLoading = true;
  isRedirecting = false;
  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.isLoading = false;
        this.userService.getCurrentUser().subscribe(user => {
          this.currentUser=user;
          this.navigateFromLogin();
        });
      } else {
        this.auth.getAccessTokenSilently().subscribe(
          (token) => {
            this.isLoading = false;
            //console.log('Token retrieved successfully:', token);
          },
          (error) => {
            this.isLoading = false;
            if (error.error === 'login_required' || error.error === 'consent_required') {
              // Redirect to login only if we haven't redirected before
              if (!this.isRedirecting) {
                this.isRedirecting = true;
                this.auth.loginWithRedirect();
              }
            }
          }
        );
      }
    });

    
  }

}


